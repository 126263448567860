import React from 'react'
import glamorous, { Div } from 'glamorous'
import { css } from 'glamor'

import { smallerThan, largerThan } from '../shared/media-queries'

import logos1 from '../imgs/home/logos2@2x.png'
import logos2 from '../imgs/home/logos2@2x.png'
import logos3 from '../imgs/home/logos2@2x.png'

import mobileLogos2 from '../imgs/home/mobile/logos-02@2x.png'
import mobileLogos3 from '../imgs/home/mobile/logos-03@2x.png'
import mobileLogos4 from '../imgs/home/mobile/logos-04@2x.png'
import mobileLogos5 from '../imgs/home/mobile/logos-05@2x.png'

const logoAnimLength = 7
const mobileFadeKeyframes = css.keyframes({
	'0%': { opacity: 0 },
	'6%': { opacity: 1 },
	'16.66%': { opacity: 1 },
	'22.66%': { opacity: 0 },
})
const desktopFadeKeyframes = css.keyframes({
	'0%': { opacity: 0 },
	'12%': { opacity: 1 },
	'33%': { opacity: 1 },
	'45%': { opacity: 0 },
})

const commonLogoImgStyles = {
	maxWidth: '100%',
	opacity: 0,
	position: 'absolute',
	animationTimingFunction: 'ease-in-out',
}
const DesktopLogoImg = glamorous.img(
	commonLogoImgStyles,
	{ width: '1028px' },
	({ delay }) => ({
		animation: `${desktopFadeKeyframes} ${logoAnimLength * 3}s infinite`,
		animationDelay: `${delay}s`,
	})
)
const MobileLogoImg = glamorous.img(commonLogoImgStyles, ({ delay }) => ({
	animation: `${mobileFadeKeyframes} ${logoAnimLength * 6}s infinite`,
	animationDelay: `${delay}s`,
}))

const GcLogos = () => {
	return (
		<Div
			maxWidth="100%"
			position="relative"
			css={{
				[smallerThan('xx-small')]: {
					paddingBottom: '13.33%',
				},
				[largerThan('xx-small')]: {
					paddingBottom: '5.84%',
					margin: '0 auto',
					width: '1028px',
				},
			}}
		>
			<Div
				css={{
					[smallerThan('xx-small')]: {
						display: 'none',
					},
				}}
			>
				<DesktopLogoImg src={logos1} delay={0} />
				<DesktopLogoImg src={logos2} delay={logoAnimLength} />
				<DesktopLogoImg src={logos3} delay={logoAnimLength * 2} />
			</Div>

			<Div
				css={{
					[largerThan('xx-small')]: {
						display: 'none',
					},
				}}
			>
				<MobileLogoImg src={mobileLogos2} delay={logoAnimLength} />
				<MobileLogoImg src={mobileLogos3} delay={logoAnimLength} />
				<MobileLogoImg src={mobileLogos4} delay={logoAnimLength * 2} />
				<MobileLogoImg src={mobileLogos5} delay={logoAnimLength * 3} />
			</Div>
		</Div>
	)
}

export default GcLogos
